import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useQuery, useMutation } from "@apollo/client"
import {
  STUDY_LISTING,
  STUDY_IN_FRANCE,
  CONSULTATION_BLOCK,
  NEWSLETTER_BLOCK,
  NEWSLETTER_SUBSCRIBE_MUTATION,
  VALIDATE_NEWSLETTER_SUBSCRIBER,
  STUDY_DETAIL_URL,
} from "../components/graphql"
import parse from "html-react-parser"
import { Link } from "gatsby"
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share"
import home_img from "../assets/images/studydetail/path-1179@1x.png"
import BounceIndicator from "react-activity/lib/Bounce"
import "react-activity/lib/Bounce/Bounce.css"
import { openPopupWidget } from "react-calendly"
import ReactMarkdown from "react-markdown"
import { validateEmailID } from "../../utils"
import addToMailchimp from "gatsby-plugin-mailchimp"
// import { Scrollbars } from 'react-custom-scrollbars';

// import chris_img from "../assets/images/christopher.png"
// import euro_img from "../assets/images/path-733@1x.png"
// import arrow_img from "../assets/images/path-305-1@1x.png"
// import aero_img from "../assets/images/aero@1x.png"
// import legal_img from "../assets/images/legal@1x.png"

import fb_icon from "../assets/images/studydetail/fb.png"
import linkedin_icon from "../assets/images/studydetail/linkedin.png"
import whatsapp_icon from "../assets/images/studydetail/whatsapp.png"
import twitter_icon from "../assets/images/studydetail/twitter.png"
import toggle_menu_icon from "../assets/images/studydetail/path-1201@1x.png"
import $ from "jquery"

const StudyInFranceDetail = ({ location, data, pageContext }) => {
  const [showing, setShowing] = useState(false)
  const [subscriberMail, setSubscriberEmail] = useState("")
  const [subscribeError, setSubscribeError] = useState("")
  const [subscribeSucess, setSubscribeSucess] = useState("")
  var showingclassName = showing
    ? "toggle-link text-left active"
    : "toggle-link text-left"

  const [banner, setBanner] = useState("")
  // const currentLocation = props.location.pathname + props.location.search
  // console.log("fffffffffffffffff",props,"-------------",props.location)
  // console.log("111111111111111",props.location.state.id)
  // console.log("$$$$$$$$$$$$$$$$$$,$$$$$$$$$$$$$$",currentLocation,"--------------",props.location.search)
  const currentURL = location.href
  // let params = new URLSearchParams(document.location.search.substring(1))

  var locationRef = location.href
  if (locationRef) {
    if (locationRef.slice(-1) === "/") {
      locationRef = locationRef.slice(0, -1)
    }
  }
  var lastContent = undefined
  var strippedContent = undefined

	React.useEffect(() => {
		if (typeof window !== `undefined`) {
			if (typeof document !== `undefined`) {
			localStorage.setItem('lastRevistedPage',window.location.pathname)
			}
		}
	});
  
  if (locationRef) {
    console.log("locationRef", locationRef)
    var splitRef = locationRef.split("/")
    console.log("splitRef 1", splitRef[splitRef.length - 1])
    console.log("splitRef 1 .charAt(0)", splitRef[splitRef.length - 1].charAt(0))
    if (splitRef[splitRef.length - 1].charAt(0) === "#"){
      console.log("splitRef 1", splitRef[splitRef.length - 2])
      lastContent = splitRef[splitRef.length - 2] + splitRef[splitRef.length - 1]
    }else{
    var lastRef = locationRef.substring(locationRef.lastIndexOf("/") + 1)
    console.log("locationRef.lastIndexOf() + 1", locationRef.substring(locationRef.lastIndexOf("/") - 1))
    lastContent = locationRef.substring(locationRef.lastIndexOf("/") + 1)
    }
    console.log("lastContent", lastContent)
    strippedContent = lastContent.substring(
      0,
      lastContent.indexOf("#") + "#".length - 1
    )

    if (strippedContent === "") {
      strippedContent = lastContent
    }
  }
  const slug = strippedContent
  let id = ""
  const slugContent = useQuery(STUDY_DETAIL_URL, {
    variables: { url: slug },
  })
  if (slugContent.data) {
    if (slugContent.data.studyListings.length !== 0) {
      id = slugContent.data.studyListings[0].id
    }
  }
  // else if(localStorage.getItem("listingId")){
  //   id = localStorage.getItem("listingId")
  // }
  else {
    id = ""
    // localStorage.setItem("listingId","")
  }

  if (!slug) {
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      window.location.replace("/")
    }
  }
  // else{
  //   localStorage.setItem("listingId",id)
  // }
  const detailContent = useQuery(STUDY_LISTING, {
    variables: { id: id },
  })
  // console.log("################",detailContent)
  const studyInFranceContent = useQuery(STUDY_IN_FRANCE)
  const consultationBlock = useQuery(CONSULTATION_BLOCK)
  const newsletterBlockContent = useQuery(NEWSLETTER_BLOCK)
  const [createSubscriber] = useMutation(NEWSLETTER_SUBSCRIBE_MUTATION)
  const existingSubscriberCheck = useQuery(VALIDATE_NEWSLETTER_SUBSCRIBER, {
    variables: { subscriber: subscriberMail },
  })
  React.useEffect(() => {
    if ($(window).width() > 991) {
      $(window).scroll(function () {
        var scroll = $(window).scrollTop()
        if ($(".consultation-section").length) {
          var distance = $(".consultation-section").offset().top
          // $window = $(window);
          var outerContentsHeight = $(".home-banner.study-detail").height()
          var outerContentsHeight1 = outerContentsHeight - 50
          var scroll1 = $(window).scrollTop() + 750
          if (scroll >= outerContentsHeight1) {
            $(".home.study").addClass("fixed-section")
          } else {
            $(".home.study").removeClass("fixed-section")
          }
          if (scroll1 >= distance) {
            $(".home.study").removeClass("fixed-section")
          }
        }
      })
    }
  }, [])
  const handleSubmit = async () => {
    if (validateEmailID(subscriberMail)) {
      try {
        var subscriberLength = existingSubscriberCheck.data
          ? existingSubscriberCheck.data.newsletterSubscribers.length
          : -1
        console.log("subscriberLength", subscriberLength)
        if (subscriberLength === 0) {
          postSubscriber()
        } else {
          setSubscribeSucess("")
          setSubscribeError("EmailID already registered")
        }
      } catch (e) {
        console.log("create user error", e.message)
      }
    } else {
      setSubscribeSucess("")
      setSubscribeError("Enter a valid mail address")
    }
  }

  const postSubscriber = async () => {
    try {
      await createSubscriber({
        variables: {
          newsletterInput: {
            data: {
              Subscriber: subscriberMail,
            },
          },
        },
      }).then((createSubscriberResponse) => {
        console.log("reponse", createSubscriberResponse.data)
        if (createSubscriberResponse.data.length !== 0) {
          const result = addToMailchimp(subscriberMail, {
            "group[223596][1]": true,
            "group[223596][2]": true,
            "group[223596][4]": true,
          })
          console.log("result", result)
          setSubscriberEmail("")
          setSubscribeError("")
          setSubscribeSucess("Subscribed successfully...!")
        }
      })
    } catch (e) {
      setSubscribeSucess("")
      console.log("post subcriber error", e.message)
    }
  }

  const newsletterBlock = () => {
    const data = newsletterBlockContent.data
    var heading = data ? data.newsletterBlock.title : ""
    var description = data ? data.newsletterBlock.shortDescription : ""
    var image = data
      ? process.env.BACKEND_URL + data.newsletterBlock.thumbnailImage.url
      : ""
    return (
      <div className="subscribe-newsletter ptop">
        <div className="newsletter">
          <h5>{heading}</h5>
          <p>{description}</p>
          <div>
            <input
              type="email"
              id="email"
              name="email"
              value={subscriberMail}
              placeholder="Email Address"
              onChange={(e) => {
                setSubscriberEmail(e.target.value)
              }}
            />
            <div className="success-message">{subscribeSucess}</div>
            <div className="error-message">{subscribeError}</div>
            {subscribeError === "" ? <br /> : null}
            <div class="talk newsletter">
              <button
                onClick={() => handleSubmit()}
                className="newsletterbutton"
              >
                {newsletterBlockContent.data
                  ? newsletterBlockContent.data.newsletterBlock.buttonName
                  : ""}
              </button>
            </div>
          </div>
          <div className="mail-image">
            <img
              src={image}
              alt={
                newsletterBlockContent.data
                  ? newsletterBlockContent.data.newsletterBlock.altthumbnailName
                  : ""
              }
            />
          </div>
        </div>
      </div>
    )
  }

  const renderConsultationBlock = () => {
    return (
      <div className="row">
        <div className="col-md-6 text pb-2">
          <div className="title">
            {consultationBlock.data.consultationBlock.title1} <br />
            {consultationBlock.data.consultationBlock.title2}
          </div>
          <p>{consultationBlock.data.consultationBlock.shortDescription}</p>
          {process.env.FILLOUT_ENQUIRY_FORM?
            // <button
            //   className="talk"
            // // onClick={(e) => {
            //   //   e.preventDefault()
            //   //   calendlyAction()
            //   // }}
            // >
           // </button>
           <div className="talk">
              <Link to= {`${process.env.FILLOUT_ENQUIRY_FORM}`} className="text-dark"  
              >
              {consultationBlock.data.consultationBlock.buttonName}
              </Link>
            </div>
           :null}
        </div>
        <div className="col-md-6 img">
          <img
            src={consultationImage ? consultationImage : ""}
            alt={consultationBlock.data.consultationBlock.altthumbnailName}
          />
        </div>
      </div>
    )
  }

  const calendlyAction = () => {
    return (
      <div>
        {openPopupWidget({
          url: "https://calendly.com/dimensionsfrance/bonjour",
          prefill: null,
          pageSettings: null,
          utm: null,
        })}
      </div>
    )
  }

  function bannerUpdateFunction() {
    if (banner === "") {
      var img = bannerImage ? bannerImage : ""
      if (img !== "") {
        setBanner(img)
      }
    }
  }

  var bannerImage = detailContent.data
    ? process.env.BACKEND_URL + detailContent.data.studyListing.bannerImage.url
    : undefined

  var consultationImage = consultationBlock.data
    ? process.env.BACKEND_URL +
      consultationBlock.data.consultationBlock.thumbnailImage.url
    : undefined

  var studyListingData = detailContent.data
  bannerUpdateFunction()

  React.useEffect(() => {
    console.log("useEffect called")
  }, [])

  const { strapiStudyListing, allStrapiHomePage } = data
  const seoTitle = strapiStudyListing !== null ? strapiStudyListing.title : ""
  const seoMetaDescription =
    strapiStudyListing !== null ? strapiStudyListing.metaDescription : ""
  const seoImage =
    allStrapiHomePage !== null
      ? allStrapiHomePage.edges[0].node.metaImage
        ? allStrapiHomePage.edges[0].node.metaImage.url
        : ""
      : ""
  if (slugContent.loading)
    return (
      <>
        <SEO
          title={seoTitle}
          description={seoMetaDescription}
          image={seoImage}
        />
        <div className={"centered"}>
          <BounceIndicator size={25} color={"#0055a4"} />
        </div>
      </>
    )

  if (detailContent.loading)
    return (
      <>
        <SEO
          title={seoTitle}
          description={seoMetaDescription}
          image={seoImage}
        />
        <div className={"centered"}>
          <BounceIndicator size={25} color={"#0055a4"} />
        </div>
      </>
    )
  if (detailContent.error) return `Error! ${studyInFranceContent.error}`

  return (
    <>
      <SEO title={seoTitle} description={seoMetaDescription} image={seoImage} />
      <Layout setTitle={studyListingData.studyListing.listingTitle} tag="study-in-france">
        {detailContent.data ? (
          <>
            {/*  Banner */}
            <section>
              {console.log("#############", studyListingData)}
              <div
                className="home-banner study-detail"
                style={{
                  backgroundImage: `url(${bannerImage})`,
                  backgroundAttachment: "fixed",
                }}
              >
                {/* <img src={bannerImage} alt={detailContent.data.studyListing.altBannerName} style=""></img> */}
                <div className="banner-overlay">
                  <div className="smalltext">
                    <img src={home_img} alt="home_img" />
                    <Link to="/">/ STUDY IN FRANCE</Link>
                  </div>
                  <div className="text">
                    {studyListingData.studyListing.bannerTitle
                      ? parse(studyListingData.studyListing.bannerTitle)
                      : ""}
                  </div>
                </div>
              </div>
            </section>

            {/*  /Banner */}
            {/* <!-- main --> */}
            <main className="home study">
              <div className="main-content">
                <div className="container">
                  {/* <!---- 3column ----> */}
                  <div className="layouts">
                    <div className="left-column">
                      <div
                        className={showingclassName}
                        onClick={() => setShowing(!showing)}
                        onKeyDown={() => setShowing(!showing)}
                        role="presentation"
                      >
                        <img src={toggle_menu_icon} alt="toggle_menu" />
                      </div>
                      {showing ? (
                        <div className="categories-name">
                          {studyInFranceContent.data
                            ? studyInFranceContent.data.studyInFrance.studyListing.map(
                                (content, index) => (
                                  <>
                                    {slug === content.url ? (
                                      <Link
                                        key={index}
                                        // to={`/study-in-france/?id=${content.id}`}
                                        to={`/study-in-france/${content.url}/`}
                                        // to={`/study-in-france-detail`}
                                        // state={{id: content.id}}
                                        className="title active"
                                      >
                                        <span>{content.menuName}</span>
                                        <br />
                                      </Link>
                                    ) : (
                                      <Link
                                        key={index}
                                        to={`/study-in-france/${content.url}/`}
                                        // to={`/study-in-france/?id=${content.id}`}
                                        // to={`/study-in-france-detail`}
                                        // state={{id: content.id}}
                                        className="title"
                                      >
                                        <span>{content.menuName}</span>
                                        <br />
                                      </Link>
                                    )}
                                  </>
                                )
                              )
                            : "null"}
                        </div>
                      ) : null}
                    </div>
                    <div className="center-column">
                      <section className="one">
                        <p>
                          {parse(
                            studyListingData
                              ? studyListingData.studyListing.longDescription
                              : ""
                          )}
                        </p>
                      </section>

                      {studyListingData ? (
                        studyListingData.studyListing.sectionContent.length !==
                        0 ? (
                          <section className="two pb50">
                            <div className="article-list">
                              <h3 className="strong">
                                  {studyListingData
                                    ? studyListingData.studyListing.sectionTitle
                                    : ""}
                              </h3>
                              {studyListingData.studyListing.sectionContent.map(
                                (sectionContent, index) => (
                                  <a key={index} href={`#section${index}`}>
                                    {`${
                                      index < 10 ? "0" + (index + 1) : index
                                    }. ` +
                                      sectionContent.title +
                                      " "}
                                  </a>
                                )
                              )}
                            </div>
                          </section>
                        ) : null
                      ) : null}

                      {studyListingData ? (
                        studyListingData.studyListing.sectionContent.length !==
                        0 ? (
                          <section className="three">
                            {studyListingData.studyListing.sectionContent.map(
                              (sectionContent, index) => (
                                <div
                                  key={index}
                                  className="article-overview pt50"
                                  id={`section${index}`}
                                >
                                  {index ===
                                  Math.floor(
                                    studyListingData.studyListing.sectionContent
                                      .length / 2
                                  )
                                    ? newsletterBlock()
                                    : null}
                                  <div className="number">{`${
                                    index < 10 ? "0" + (index + 1) : index
                                  }`}</div>
                                  <div className="title">
                                    {sectionContent.title + " "}
                                  </div>
                                  <div className="desc">
                                    <ReactMarkdown
                                      source={sectionContent.description}
                                      transformImageUri={(uri) =>
                                        uri.startsWith("http")
                                          ? uri
                                          : `${process.env.BACKEND_URL}${uri}`
                                      }
                                    />
                                  </div>
                                  {sectionContent.subContent.length !== 0
                                    ? sectionContent.subContent.map(
                                        (subContent, subIndex) => (
                                          <ul key={subIndex}>
                                            <li>
                                              <div className="subtitle">
                                                {subContent.title}
                                              </div>
                                              <ReactMarkdown
                                                source={subContent.description}
                                              />
                                            </li>
                                          </ul>
                                        )
                                      )
                                    : null}
                                </div>
                              )
                            )}
                          </section>
                        ) : null
                      ) : null}
                    </div>

                    <div className="right-column">
                      <div className="spread-the-love">
                        <span className="share-small-text">
                          SHARE
                          <br />
                        </span>
                        <span className="grey-color-text">
                          Spread the love with someone.
                        </span>
                      </div>
                      <div className="social-img">
                        <FacebookShareButton
                          quote={
                            seoMetaDescription || seoTitle
                          }
                          url={currentURL}
                          hashtag="#dimensionsFrance"
                        >
                          <img src={fb_icon} alt="facebook" />
                        </FacebookShareButton>
                      </div>
                      <div className="social-img">
                        <LinkedinShareButton
                          title={"Dimensions France"}
                          summary={
                            seoMetaDescription || seoTitle
                          }
                          url={currentURL}
                          source={currentURL}
                          hashtag="#dimensionsFrance"
                        >
                          <img src={linkedin_icon} alt="linkedin" />
                        </LinkedinShareButton>
                      </div>
                      <div className="social-img">
                        <WhatsappShareButton
                          title={
                            seoMetaDescription || seoTitle
                          }
                          url={currentURL}
                          hashtag="#dimensionsFrance"
                        >
                          <img src={whatsapp_icon} alt="whatsapp" />
                        </WhatsappShareButton>
                      </div>
                      <div className="social-img">
                        <TwitterShareButton
                          title={
                            seoMetaDescription || seoTitle
                          }
                          url={currentURL}
                          hashtag="#dimensionsFrance"
                        >
                          <img src={twitter_icon} alt="twitter" />
                        </TwitterShareButton>
                      </div>
                    </div>
                  </div>
                  {/* <!---- /3column ----> */}
                  {/* Consultation */}
                  <section
                    className="consultation-section ptop"
                    style={{ backgroundColor: "#fff" }}
                  >
                    <div className="consultation pb100">
                      {consultationBlock.data ? renderConsultationBlock() : ""}
                    </div>
                  </section>
                  {/* /Consultation */}
                  {/* <section className="articles ptop">
                  <div className="related-articles">
                    <div className="title">Related Articles</div>
                    <div className="pull-right viewall">VIEW ALL</div>
                    <div className="articles-list">
                      <div className="list">
                        <div className="top">
                          <div className="image">
                            <img src={chris_img} alt=""></img>
                          </div>
                          <div className="detail">
                            <div className="name">RUSTOM PAWRI</div>
                            <div className="date">5 min | April 22</div>
                          </div>
                        </div>
                        <div className="middle">
                          Scholarships For Syrian Students In Exile In France
                        </div>
                        <div className="bottom">
                          <img src={euro_img} alt=""></img> <span>SCHOLARSHIP</span>
                        </div>
                      </div>
                      <div className="list">
                        <div className="top">
                          <div className="image">
                            <img src={chris_img} alt=""></img>{" "}
                          </div>
                          <div className="detail">
                            <div className="name">RUSTOM PAWRI</div>
                            <div className="date">5 min | April 22</div>
                          </div>
                        </div>
                        <div className="middle">
                          Good Deals When Travelling By Train With Passenger
                        </div>
                        <div className="bottom">
                          <img src={aero_img} alt=""></img>{" "}
                          <span>TRAVEL PREPARATION</span>
                          <img
                            className={"right"}
                            src={arrow_img}
                            alt=""
                          ></img>{" "}
                        </div>
                      </div>
                      <div className="list">
                        <div className="top">
                          <div className="image">
                            <img src={chris_img} alt=""></img>
                          </div>
                          <div className="detail">
                            <div className="name">RUSTOM PAWRI</div>
                            <div className="date">5 min | April 22</div>
                          </div>
                        </div>
                        <div className="middle">
                          Instructors And Researches Give Students Two Types
                        </div>
                        <div className="bottom">
                          <img src={legal_img} alt=""></img>{" "}
                          <span>LEGAL FORMALITIES</span>
                        </div>
                      </div>
                      <div className="list">
                        <div className="top">
                          <div className="image">
                            <img src={chris_img} alt=""></img>{" "}
                          </div>
                          <div className="detail">
                            <div className="name">RUSTOM PAWRI</div>
                            <div className="date">5 min | April 22</div>
                          </div>
                        </div>
                        <div className="middle">
                          Scholarships For Syrian Students In Exile In France
                        </div>
                        <div className="bottom">
                          <img src={euro_img} alt=""></img> <span>SCHOLARSHIP</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </section> */}
                </div>
                {/* <!-- /container ---> */}
              </div>
            </main>
            {/* /main */}
          </>
        ) : null}
      </Layout>
    </>
  )
}

export default StudyInFranceDetail

export const query = graphql`
  query studyInFranceDetailWithID($pageID: String) {
    strapiStudyListing(id: { eq: $pageID }) {
      title
      metaDescription
    }
    allStrapiHomePage {
      edges {
        node {
          metaImage {
            url
          }
        }
      }
    }
  }
`
