export function validatePassword(password) {
  var passRegex = new RegExp("^(?=.{8,})");
  if(passRegex.test(password)){
    return true
  }else {
    return false
  }
}
export function validateEmailID(mail) {
  if (
    /\w+([\*.-_]?\w+)*@(?!_)\w+(\w+)*(\.\w{2,3})+$/gm    
.test(mail)
  ) {
    return true
  }
  return false
}
export function validateContactNumber(number) {
  if (
    /^[0-9]{10}$/.test(
      number
    )
  ) {
    return true
  }
  return false
}



